
<template>
    <div>
        <Header />
        <div class="container">

            <v-btn :loading="loading" @click="generaTurnos" size="x-large" dark>
                Generar Turnos
                <template v-slot:loader>
                    <v-progress-linear indeterminate></v-progress-linear>
                </template>
            </v-btn>
            <!-- Botón para exportar a Excel -->
            <v-btn @click="exportToExcel" color="success">Exportar a Excel</v-btn>
            <br />
            <v-overlay :value="loading">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-overlay>
            <Table :Turnos="ListaTurnos" ref="tableComponent" />
            <!-- Dialog de sesión expirada -->
            <v-dialog v-model="showSessionExpiredDialog" max-width="400">
                <v-card>
                    <v-card-title>¡Sesión expirada!</v-card-title>
                    <v-card-text>
                        Tu sesión ha expirado. Por favor, inicia sesión nuevamente.
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" @click="redirectToLogin">Iniciar sesión</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>


<script>
import Header from '@/components/HeaderC.vue'
import Table from '@/components/TableTurnosC.vue'
import axios from 'axios'
import { utils as XLSXUtils, writeFile as writeExcel } from 'xlsx';


export default {
    name: 'TurnosView',
    data() {
        return {
            showSessionExpiredDialog: false,
            ListaTurnos: [],
            loading: false,
            validToken: false
        }
    },
    components: {
        Header,
        Table
    },
    mounted: function () {
        this.initialize();
    },
    methods: {
        async initialize(){
            await this.validateToken();
            await this.obtieneRegistros();
        },
        redirectToLogin() {
            // localStorage.removeItem('token');
            // localStorage.removeItem('user');
            // localStorage.removeItem('username');
            // localStorage.removeItem('name');

            this.$cookies.remove('token');
            this.$cookies.remove('user');
            this.$cookies.remove('username');
            this.$cookies.remove('name');
            // Redirigir al usuario al componente de inicio de sesión
            this.$router.push('/');
        },
        async generaTurnos() {

            await this.validateToken()
            this.loading = true;
            const apiUrl = 'https://pc61cf7yyj.execute-api.us-east-1.amazonaws.com/dev/api/turnos';

            // Configuración para la solicitud GET con el token en el encabezado Authorization
            const config = {
                headers: {
                    Authorization: `Bearer ${this.$cookies.get('token')}`
                }
            };

            const bodyReq = {
                "idProcesion": 1
            }

            axios.post(apiUrl, bodyReq, config)
                .then(() => {

                    // console.log(response)
                    this.obtieneRegistros()
                    this.loading = false; // Desactivar animación de carga
                    // console.log(this.ListaTurnos)
                })
                .catch(() => {
                    // console.error('Error al obtener datos de la API', error);
                    this.loading = false; // Desactivar animación de carga en caso de error
                });


        },
        validateToken() {

            // const token = localStorage.token
            const token = this.$cookies.get('token');
            this.loading = true;
            const apiUrl = `https://wm1xvvtarj.execute-api.us-east-1.amazonaws.com/dev/validateToken?token=${token}`;
            // Configuración para la solicitud GET con el token en el encabezado Authorization
            // console.log("apiurl", apiUrl)
            // Realizar solicitud GET a la API original con el token en el encabezado
            return axios.get(apiUrl)
                .then(response => {

                    const username = response.data.decoded.username;

                    this.$cookies.set('username', username, '1d', '', '', true, 'Strict');

                    // localStorage.username = response.data.decoded.username;
                    this.validToken = true;
                    this.loading = false; // Desactivar animación de carga

                })
                .catch(() => {
                    this.validToken = false
                    this.showSessionExpiredDialog = true;
                    // console.error(error)
                    // console.error('Error al obtener datos de la API', error);
                    this.loading = false; // Desactivar animación de carga en caso de error
                    // console.log("sesion invalida")
                    // localStorage.clear
                    this.clearAllCookies;
                    // this.$router.push('/');

                });
        },
        clearAllCookies() {
            const allCookies = this.$cookies.keys();
            for (const cookie of allCookies) {
                this.$cookies.remove(cookie);
            }
            // console.log('All cookies cleared');
        },
        async obtieneRegistros() {

            await this.validateToken()
            this.loading = true;
            const apiUrl = 'https://pc61cf7yyj.execute-api.us-east-1.amazonaws.com/dev/api/turnos/1';
            const token = this.$cookies.get('token');
            // Configuración para la solicitud GET con el token en el encabezado Authorization
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };



            return axios.get(apiUrl, config)
                .then(response => {

                    this.ListaTurnos = response.data.records

                    this.loading = false; // Desactivar animación de carga
                    // console.log(this.ListaTurnos)
                })
                .catch(() => {
                    // console.error('Error al obtener datos de la API', error);
                    this.loading = false; // Desactivar animación de carga en caso de error
                });
        },
        exportToExcel() {
            const wb = XLSXUtils.book_new();
            const ws = XLSXUtils.json_to_sheet(this.ListaTurnos);
            XLSXUtils.book_append_sheet(wb, ws, 'Turnos');

            writeExcel(wb, 'Turnos.xlsx');
        }

    }
}
</script>