<!-- eslint-disable vue/valid-v-slot -->
<template>
    <div>
        <v-data-table :headers="headers" :items="desserts" sort-by="ID_Cargador" class="elevation-1" :search="search">
            <template v-slot:top>
                <v-overlay :value="loading">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-overlay>
                <v-alert v-model="NotiExito" type="success" dismissible>
                    {{ notiMessage }}
                </v-alert>
                <v-alert v-model="notiError" type="error" dismissible>
                    {{ errorMessage }}
                </v-alert>
                <v-toolbar flat>

                    <v-toolbar-title>Cargadores</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                        hide-details></v-text-field>

                    <br />

                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="creacion = true"
                                :disabled="loading">
                                Inscribir Cargador
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-container>
                                    <v-row>

                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field v-model="editedItem.Nombre" label="Nombre" @input="validateName"
                                                :rules="[v => !!v || 'Nombre es requerido', v => /^[a-zA-ZáéíóúñÁÉÍÓÚ\s]+$/.test(v) || 'Ingrese solo letras en el nombre']"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field v-model="editedItem.Apellido" label="Apellido"
                                                @input="validateLastName"
                                                :rules="[v => !!v || 'Apellido es requerido', v => /^[a-zA-ZáéíóúñÁÉÍÓÚ\s]+$/.test(v) || 'Ingrese solo letras en el apellido']"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field v-model="editedItem.Altura" label="Altura" @input="validateHeight"
                                                :rules="[v => !!v || 'Altura es requerida', v => /^\d{1,2}(\.\d{1,2})?$/.test(v) || 'Ingrese un número válido de altura']"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4" v-if="creacion">
                                            <v-menu :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field v-model="editedItem.fechaNacimiento"
                                                        label="Fecha nacimiento" readonly v-bind="attrs" v-on="on"
                                                        :rules="[v => !!v || 'Fecha nacimiento es requerido']"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="editedItem.fechaNacimiento"
                                                    no-title></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field v-model="editedItem.Direccion" label="Dirección"
                                                :rules="[v => !!v || 'Dirección es requerido']"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field v-model="editedItem.Telefono" label="Telefono"
                                                @input="validatePhoneNumber"
                                                :rules="[v => !!v || 'Telefono es requerido', v => /^\d{8}$/.test(v) || 'Ingrese un número de teléfono válido']"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field v-model="editedItem.Correo" label="Correo"
                                                :rules="[v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'Ingrese un correo electrónico válido']"></v-text-field>
                                        </v-col>
                                        <!-- <v-col cols="12" sm="6" md="4" v-if="creacion">
                                            <v-text-field v-model="editedItem.Documento_Identificacion" label="CUI"
                                                @input="validateCUI" :disabled="!creacion"
                                                :rules="[v => !!v || 'CUI es requerido', v => /^\d+$/.test(v) || 'Ingrese solo números en el CUI']"></v-text-field>
                                        </v-col> -->
                                        <v-col cols="12" sm="6" md="4">
                                            <v-select v-model="editedItem.Tipo_cargador" label="Tipo"
                                                :items="['cargador', 'aspirante']"
                                                :rules="[v => !!v || 'tipo es requerido']"></v-select>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-alert type="error" variant="outlined" v-model="notiRequerido"
                                    title="Completa el formulario">{{ msgRequerido }}</v-alert>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close" :disabled="loading">
                                    Cancelar
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="save" :disabled="loading || !formValidate">
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5">Estas seguro que deseas eliminar al cargador {{ editedItem.Nombre
                            }}</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete" :disabled="loading">No,
                                    cancelar</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm" :disabled="loading">Si,
                                    eliminar</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)">
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                    Reset
                </v-btn>
            </template>
        </v-data-table>
        <!-- Dialog de sesión expirada -->
        <v-dialog v-model="showSessionExpiredDialog" max-width="400">
            <v-card>
                <v-card-title>¡Sesión expirada!</v-card-title>
                <v-card-text>
                    Tu sesión ha expirado. Por favor, inicia sesión nuevamente.
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="redirectToLogin">Iniciar sesión</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios';


export default {
    props: {
        cargadores: [],
    },
    data: () => ({
        showSessionExpiredDialog: false,
        dialog: false,
        formValidate: true,
        loading: false,
        dialogDelete: false,
        NotiExito: false,
        notiMessage: "",
        notiRequerido: false,
        msgRequerido: "",
        notiError: false,
        errorMessage: "",
        search: '',
        creacion: false,
        validToken: false,
        headers: [
            {
                text: 'CargadorID',
                align: 'start',
                sortable: false,
                value: 'ID_Cargador',
            },
            { text: 'Nombre', value: 'Nombre' },
            { text: 'Apellido', value: 'Apellido' },
            { text: 'Altura', value: 'Altura' },
            { text: 'FechaRegistro', value: 'Fecha_Registro' },
            { text: 'Dirección', value: 'Direccion', sortable: false },
            // { text: 'CUI', value: 'Documento_Identificacion' },
            { text: 'Telefono', value: 'Telefono' },
            { text: 'Correo', value: 'Correo' },
            { text: 'Tipo', value: 'Tipo_cargador' },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        desserts: [{
            "ID_Cargador": 1,
            "Nombre": "Sangría",
            "Apellido": "Sangría",
            "Fecha_Nacimiento": "2024-01-01T00:00:00.000Z",
            "Altura": "2.00",
            "Fecha_Registro": "2024-01-01T00:00:00.000Z",
            "Direccion": "0",
            // "Documento_Identificacion": "0",
            "Estado": "A",
            "Telefono": "0",
            "Correo": "0",
            "Tipo_cargador": "cargador"
        },],
        editedIndex: -1,
        editedItem: {
            ID_Cargador: '',
            Nombre: '',
            Apellido: '',
            Altura: '',
            Fecha_Registro: '',
            fechaNacimiento: '',
            Direccion: '',
            // Documento_Identificacion: '',
            Telefono: '',
            Correo: '',
            Tipo_cargador: ''
        },
        defaultItem: {
            ID_Cargador: '',
            Nombre: '',
            Apellido: '',
            Altura: '',
            Fecha_Registro: '',
            fechaNacimiento: '',
            Direccion: '',
            // Documento_Identificacion: '',
            Telefono: '',
            Correo: '',
            Tipo_cargador: ''
        },
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Crear Cargador' : 'Editar Cargador'
        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
        cargadores: {
            handler(newCargadores) {
                if (newCargadores.length > 0) {
                    this.initialize();
                }
            },
            deep: true
        },
        'editedItem.Correo'(newValue, oldValue) {
            // Validar el correo electrónico solo si ha cambiado
            if (newValue !== oldValue) {
                this.validateEmail();
            }
        },
    },
    created() {
        this.initialize()
    },
    methods: {
        redirectToLogin() {
            // localStorage.removeItem('token');
            // localStorage.removeItem('user');
            // localStorage.removeItem('username');
            // localStorage.removeItem('name');

            this.$cookies.remove('token');
            this.$cookies.remove('user');
            this.$cookies.remove('username');
            this.$cookies.remove('name');

            // Redirigir al usuario al componente de inicio de sesión
            this.$router.push('/');
        },
        validateName() {
            const regex = /^[a-zA-ZáéíóúñÁÉÍÓÚ\s]+$/;
            if (!regex.test(this.editedItem.Nombre)) {
                this.editedItem.Nombre = this.editedItem.Nombre.slice(0, -1);
                this.formValidate = false;
            } else {
                this.formValidate = true;
            }
        },
        validateLastName() {
            const regex = /^[a-zA-ZáéíóúñÁÉÍÓÚ\s]+$/;
            if (!regex.test(this.editedItem.Apellido)) {
                this.editedItem.Apellido = this.editedItem.Apellido.slice(0, -1);
                this.formValidate = false;
            } else {
                this.formValidate = true;
            }
        },
        validateHeight() {
            const regex = /^\d{1,2}(\.\d{1,2})?$/;
            if (!regex.test(this.editedItem.Altura)) {
                this.editedItem.Altura = this.editedItem.Altura.slice(0, -1);
                this.formValidate = false;
            } else {
                this.formValidate = true;
            }
        },
        validatePhoneNumber() {
            const regex = /^\d{0,8}$/;
            if (!regex.test(this.editedItem.Telefono)) {
                this.editedItem.Telefono = this.editedItem.Telefono.slice(0, -1);
                this.formValidate = false;
            } else {
                this.formValidate = true;
            }
        },
        validateEmail() {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!regex.test(this.editedItem.Correo)) {
                this.editedItem.Correo = this.editedItem.Correo.slice(0, -1);
                this.formValidate = false;
            } else {
                this.formValidate = true;
            }
        },
        // validateCUI() {
        //     const regex = /^\d{0,}$/;
        //     if (!regex.test(this.editedItem.Documento_Identificacion)) {
        //         this.editedItem.Documento_Identificacion = this.editedItem.Documento_Identificacion.slice(0, -1);
        //         this.formValidate = false;
        //     } else {
        //         this.formValidate = true;
        //     }
        // },
        hideNotification() {
            setTimeout(() => {
                this.NotiExito = false;
                this.notiError = false;
            }, 10000)
        },
        initialize() {
            this.validateToken()
            this.desserts = this.cargadores
            // console.log('ejecuta initialize')
        },

        editItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true

        },

        deleteItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true

        },

        async deleteItemConfirm() {
            this.desserts.splice(this.editedIndex, 1)
            await this.OrquestaEliminacion()
            this.closeDelete()
            // this.EliminaCargador()
            
        },

        close() {
            this.dialog = false
            this.creacion = false

            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        async save() {
            this.msgRequerido = ""
            this.notiRequerido = false
            this.validaReq();

            if (this.notiRequerido) {
                // this.creacion = false
                return
            }


            try {
                if (this.editedIndex > -1) {
                    Object.assign(this.desserts[this.editedIndex], this.editedItem)
                    await this.ActualizaCargador()
                } else {
                    await this.creaCargador();
                    // this.desserts.push(this.editedItem)
                }
                this.close()
            } catch (error) {
                // console.error('error al guardar el cargador: ', error)
            }


        },
        validaReq() {
            this.msgRequerido = "Los campos "
            if (!this.editedItem.Nombre) {
                this.notiRequerido = true
                this.msgRequerido = this.msgRequerido + "Nombre"
            }
            if (!this.editedItem.Apellido) {
                this.notiRequerido = true
                this.msgRequerido = this.msgRequerido + ", Apellido"
            }
            if (!this.editedItem.Altura) {
                this.notiRequerido = true
                this.msgRequerido = this.msgRequerido + ", Altura"
            }
            if (!this.editedItem.fechaNacimiento && this.creacion) {
                this.notiRequerido = true
                this.msgRequerido = this.msgRequerido + ", Fecha nacimiento"
            }
            if (!this.editedItem.Direccion) {
                this.notiRequerido = true
                this.msgRequerido = this.msgRequerido + ", Dirección"
            }
            // if (!this.editedItem.Documento_Identificacion) {
            //     this.notiRequerido = true
            //     this.msgRequerido = this.msgRequerido + ", CUI"
            // }
            if (!this.editedItem.Tipo_cargador) {
                this.notiRequerido = true
                this.msgRequerido = this.msgRequerido + ", Tipo de cargador"
            }
            this.msgRequerido = this.msgRequerido + " son requeridos."

        },
        async OrquestaEliminacion() {
            this.loading = true
            await this.validateToken();
            if (!this.validToken) {
                // localStorage.clear
                this.clearAllCookies;
                return
            }
            const token = this.$cookies.get('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            
            const idCargador = this.editedItem.ID_Cargador
            // console.log('orquesta idcargador',idCargador)
            await this.EliminaCargador(idCargador,config);
            
            // await this.EliminaInscripcion(idCargador,config);
            this.loading = false
        },
        clearAllCookies() {
            const allCookies = this.$cookies.keys();
            for (const cookie of allCookies) {
                this.$cookies.remove(cookie);
            }
            // console.log('All cookies cleared');
        },
        EliminaCargador(idCargador,config) {
            // const idCargador = this.editedItem.ID_Cargador
            // this.loading = true;
            // console.log('idcargador elimina cargador:',idCargador)
            // const apiUrl = `http://localhost:3000/dev/api/cargadores/${idCargador}`;
            const apiUrl = `https://62jecp6v6f.execute-api.us-east-1.amazonaws.com/dev/api/cargadores/${idCargador}`;


            
            return axios.delete(apiUrl, config)
                .then(() => {
                    // this.loading = false;
                    this.notiMessage = "El cargador ha sido eliminado correctamente"
                    this.NotiExito = true

                })
                .catch(() => {
                    // console.error('Error al obtener datos de la API', error);
                    this.errorMessage = "Hubo un problema en la eliminación del cargador, "
                    this.notiError = true;
                    this.hideNotification();
                    // this.loading = false;
                });
        },
        EliminaInscripcion(idCargador,config) {
            // console.log(idCargador)
            this.loading = true;


            const apiUrl = `https://wj7gxic747.execute-api.us-east-1.amazonaws.com/dev/api/inscripciones/${idCargador}`;
            // const apiUrl = `http://localhost:3000/dev/api/inscripciones/${idCargador}`;
            
            const bodyReq = {
                "usuario_responsable": this.$cookies.get('username')//localStorage.username
            }

            

            // console.log(apiUrl)
            // console.log(bodyReq)
            // console.log(config)
            return axios.delete(apiUrl, bodyReq, config)
                .then(() => {
                    this.loading = false;
                    this.notiMessage = "El cargador ha sido eliminado correctamente"
                    this.NotiExito = true

                })
                .catch(() => {
                    // console.error('Error al obtener datos de la API', error);
                    this.errorMessage = "Hubo un problema en la eliminación del cargador, "
                    this.notiError = true;
                    this.hideNotification();
                    this.loading = false;
                });
        },
        async creaCargador() {

            this.loading = true;
            await this.validateToken();
            // console.log("antes de validar", this.validToken)
            if (!this.validToken) {
                // console.log("sesion invalida")
                // localStorage.clear
                this.clearAllCookies();
                //this.$router.push('/');
                return
            }
            
            // Configurar el token de autorización en el encabezado de la solicitud
            // const token = localStorage.getItem('token');
            const token = this.$cookies.get('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            const bodyReq = {

                "nombre": this.editedItem.Nombre,
                "apellido": this.editedItem.Apellido,
                "fechaNacimiento": this.editedItem.fechaNacimiento,
                "altura": this.editedItem.Altura,
                "direccion": this.editedItem.Direccion,
                // "documentoIdentificacion": this.editedItem.Documento_Identificacion,
                "telefono": this.editedItem.Telefono,
                "correo": this.editedItem.Correo,
                "sexo": "M",
                "usuarioResponsable": this.$cookies.get('username')//localStorage.username
            };
            const apiUrl = 'https://62jecp6v6f.execute-api.us-east-1.amazonaws.com/dev/api/cargadores'
            

            // Enviar la solicitud para crear un nuevo cargador

            return axios.post(apiUrl, bodyReq, config)
                .then(response => {

                    this.editedItem.ID_Cargador = response.data.cargadorId
                    this.editedItem.Fecha_Registro = response.data.fechaRegistro
                    this.desserts.push(this.editedItem)
                    // this.loading = false;
                    // this.notiMessage = "El cargador ha sido creado correctamente"
                    // this.NotiExito = true
                    this.hideNotification();

                    const inscripcionUrl = 'https://wj7gxic747.execute-api.us-east-1.amazonaws.com/dev/api/inscripciones'
                    const bodyReqInscripcion = {
                        "nuevo_id_cargador": this.editedItem.ID_Cargador,
                        "nuevo_id_procesion": 1,
                        "usuario_responsable": this.$cookies.get('username'),//localStorage.username,
                        "tipo_cargador": this.editedItem.Tipo_cargador
                    };
                    
                    // console.log('bodyrq', bodyReqInscripcion)
                    return axios.post(inscripcionUrl, bodyReqInscripcion, config)
                        .then(() => {
                            // console.log(response)


                            this.loading = false;
                            this.notiMessage = "El cargador ha sido creado correctamente"
                            this.NotiExito = true
                            this.hideNotification();

                        })
                        .catch(error => {
                            this.loading = false;
                            // console.error('Error al inscribir el cargador:', error);
                            this.errorMessage = "Hubo un problema en la Inscripción del cargador, " + error.response.data.message
                            this.notiError = true;
                            this.msgRequerido = "Hubo un problema en la Inscripción del cargador," + error.response.data.message
                            this.notiRequerido = true;
                            this.hideNotification();


                        })
                })
                .catch(error => {
                    this.loading = false;
                    // console.error('Error al crear el cargador:', error);

                    this.errorMessage = error.response.data.message
                    this.notiError = true;
                    this.msgRequerido = error.response.data.message
                    this.notiRequerido = true;
                    this.hideNotification();
                    throw error;
                });
        },
        validateToken() {

            // const token = localStorage.token
            const token = this.$cookies.get('token')
            // this.loading = true;
            const apiUrl = `https://wm1xvvtarj.execute-api.us-east-1.amazonaws.com/dev/validateToken?token=${token}`;
            // Configuración para la solicitud GET con el token en el encabezado Authorization

            // console.log("apiurl", apiUrl)
            // Realizar solicitud GET a la API original con el token en el encabezado
            return axios.get(apiUrl)
                .then(response => {

                    const username = response.data.decoded.username;
                    this.$cookies.set('username', username, '1d', '', '', true, 'Strict');

                    // localStorage.username = response.data.decoded.username;
                    this.validToken = true;
                    // this.loading = false; // Desactivar animación de carga

                })
                .catch(() => {
                    this.validToken = false
                    this.showSessionExpiredDialog = true;
                    // console.error(error)
                    // console.error('Error al obtener datos de la API', error);
                    // this.loading = false; // Desactivar animación de carga en caso de error
                });
        },
        async ActualizaCargador() {
            this.loading = true;
            const idCargador = this.editedItem.ID_Cargador
            await this.validateToken();
            // console.log("antes de validar", this.validToken)
            if (!this.validToken) {
                // localStorage.clear
                this.clearAllCookies()
                return
            }
            const apiUrl = `https://62jecp6v6f.execute-api.us-east-1.amazonaws.com/dev/api/cargadores/${idCargador}`;
            const token = this.$cookies.get('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            const bodyReq = {

                "nombre": this.editedItem.Nombre,
                "apellido": this.editedItem.Apellido,
                "fechaNacimiento": this.editedItem.fechaNacimiento,
                "altura": this.editedItem.Altura,
                "direccion": this.editedItem.Direccion,
                "telefono": this.editedItem.Telefono,
                "correo": this.editedItem.Correo,
                "usuarioResponsable": this.$cookies.get('username')//localStorage.username
            }
            const tipoCargador = this.editedItem.Tipo_cargador
            axios.put(apiUrl, bodyReq, config)
                .then(() => {

                    // this.notiMessage = "El cargador ha sido actualizado correctamente"
                    // this.loading = false;
                    // this.NotiExito = true
                    // this.hideNotification();

                    // const idCargador = this.editedItem.ID_Cargador
                    this.loading = true;

                    const inscripcionUrl = `https://wj7gxic747.execute-api.us-east-1.amazonaws.com/dev/api/inscripciones/${idCargador}`;
                    const bodyReqInscripcion = {
                        "usuario_responsable": this.$cookies.get('username'),//localStorage.username,
                        "tipo_cargador": tipoCargador
                    };
                    // console.log(bodyReqInscripcion)


                    // console.log('bodyrq',bodyReqInscripcion)
                    return axios.put(inscripcionUrl, bodyReqInscripcion, config)
                        .then(() => {
                            // console.log(response)
                            // console.log("exito inscripcion")

                            this.loading = false;
                            this.notiMessage = "El cargador ha sido actualizado correctamente"
                            this.NotiExito = true
                            this.hideNotification();

                        })
                        .catch(() => {
                            this.loading = false;
                            // console.error('Error al inscribir el cargador:', error);
                            this.errorMessage = "Hubo un problema en la actualización del cargador, "
                            this.notiError = true;
                            this.msgRequerido = "Hubo un problema en la actualización del cargador,"
                            this.notiRequerido = true;
                            this.hideNotification();


                        })

                })
                .catch(error => {
                    this.loading = false;
                    // if (error.response.data.Message = "User is not authorized to access this resource with an explicit deny") {
                    //     this.$router.push('/');
                    // }
                    // console.log(error)
                    this.errorMessage = "Hubo un problema en la actualización del cargador, " + error.response.data.message
                    this.notiError = true;
                    this.msgRequerido = "Hubo un problema en la actualización del cargador, " + error.response.data.message
                    this.notiRequerido = true;
                    this.hideNotification();
                });
        }
    },
}
</script>