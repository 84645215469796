<template>
    <b-navbar toggleable="lg" type="dark" variant="dark">
        <b-navbar-brand href="#" class="brand-link">
            <router-link to="/dashboard" class="brand-link" style="text-decoration: none;">Inicio</router-link>
            
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
                <router-link to="/inscripciones" class="brand-link" style="text-decoration: none;">Inscripciones</router-link>
            </b-navbar-nav>
            <b-navbar-nav>
                <router-link to="/turnos" class="brand-link" style="text-decoration: none;">Asignación de turnos</router-link>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">

                <b-navbar-brand right href="#" class="brand-link" @click="logout()">Cerrar Sesión</b-navbar-brand>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
export default {
    methods: {
        logout() {
            // Eliminar el token del almacenamiento local
            // localStorage.removeItem('token');
            // localStorage.removeItem('user');
            // localStorage.removeItem('username');
            // localStorage.removeItem('name');
            this.clearAllCookies();
            // Redirigir a la página de inicio de sesión u otra página relevante
            // window.location.href = '/login'; // Redirigir a la página de inicio de sesión, por ejemplo
            this.$router.push('/');
        },
        clearAllCookies() {
            const allCookies = this.$cookies.keys();
            for (const cookie of allCookies) {
                this.$cookies.remove(cookie);
            }
            // console.log('All cookies cleared');
        },
    }
}
</script>

<style scoped>
/* Define estilos para la clase personalizada brand-link */
.brand-link {
    color: white;
    /* Cambia el color del texto a blanco */
    margin-left: 20px;
    /* Ajusta el margen derecho para mover la palabra "Inicio" hacia la derecha */
}
</style>