
<template>
    <div>
        <v-data-table :headers="headers" :items="desserts" sort-by="Numero_Turno" class="elevation-1" :search="search" multi-sort>
            <template v-slot:top>
                <v-overlay :value="loading">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-overlay>
                <v-alert v-model="NotiExito" type="success" dismissible>
                    {{ notiMessage }}
                </v-alert>
                <v-toolbar flat>

                    <v-toolbar-title>Turnos</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                        hide-details></v-text-field>

                </v-toolbar>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                    Reset
                </v-btn>
            </template>
        </v-data-table>
        <!-- Dialog de sesión expirada -->
        <v-dialog v-model="showSessionExpiredDialog" max-width="400">
        </v-dialog>
    </div>
</template>

<script>



export default {
    props: {
        Turnos: [],
    },
    watch: {
        Turnos: {
            immediate: true,
            handler(newValue) {
                if (newValue && newValue.length > 0) {
                    this.initialize();
                }
            }
        }
    },
    data: () => ({
        showSessionExpiredDialog: false,
        loading: false,
        NotiExito: false,
        notiMessage: "",
        search: '',
        validToken: false,
        headers: [
            {text: 'Cargador',align: 'start',sortable: false,value: 'Nombre_Cargador'},
            { text: 'Altura', value: 'Altura' },
            { text: 'Turno', value: 'Numero_Turno' },
            { text: 'Brazo', value: 'Numero_Brazo' },
            { text: 'Identificación', value: 'Documento_Identificacion' },
            { text: 'Fecha de Inscripcion', value: 'Fecha_Inscripcion' },
        ],
        desserts: [{
            "Nombre_Cargador": "Cargador 1",
            "Altura": 1.40,
            "Numero_Turno": 1,
            "Numero_Brazo": 1,
            "Documento_Identificacion": "000000000000",
            "Fecha_Inscripcion": "2024-01-01T00:00:00.000Z",
        },],
    }),
    created() {
        this.initialize()
    },
    methods: {
        redirectToLogin() {
            // Redirigir al usuario al componente de inicio de sesión
            // localStorage.removeItem('token');
            // localStorage.removeItem('user');
            // localStorage.removeItem('username');
            // localStorage.removeItem('name');
            this.clearAllCookies();
            this.$router.push('/');
        },
        clearAllCookies() {
            const allCookies = this.$cookies.keys();
            for (const cookie of allCookies) {
                this.$cookies.remove(cookie);
            }
            // console.log('All cookies cleared');
        },
        hideNotification() {
            setTimeout(() => {
                this.NotiExito = false;
                this.notiError = false;
            }, 10000)
        },
        initialize() {
            this.desserts = this.Turnos
            // console.log('ejecuta initialize')
            // console.log(this.Turnos)
        }

    }
}
</script>