import { render, staticRenderFns } from "./InscripcionesView.vue?vue&type=template&id=355e9e0b&scoped=true"
import script from "./InscripcionesView.vue?vue&type=script&lang=js"
export * from "./InscripcionesView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "355e9e0b",
  null
  
)

export default component.exports