<template>
    <div>
        <Header />
        <v-overlay :value="loading">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-overlay>
        <br />
        <br />
        <br />
        <h1 style="text-align: center;">Bienvenido {{ name }}</h1>
        <div class="container">
            <div class="container-fluid">
                <div class="row">

                    <!-- Icon Cards-->
                    <div class="col-lg-4 col-md-4 col-sm-6 col-12 mb-2 mt-4">
                        <div class="inforide">
                            <div class="row">
                                <div class="col-lg-3 col-md-4 col-sm-4 col-4 rideone">
                                    <b-icon icon="people-fill" style="width: 90%; height: 90%;"></b-icon>
                                </div>
                                <div class="col-lg-9 col-md-8 col-sm-8 col-8 fontsty">
                                    <h4>Aspirantes</h4>
                                    <h2>{{ aspirantes }}</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-6 col-12 mb-2 mt-4">
                        <div class="inforide">
                            <div class="row">
                                <div class="col-lg-3 col-md-4 col-sm-4 col-4 ridetwo">
                                    <b-icon icon="people-fill" style="width: 90%; height: 90%;"></b-icon>
                                </div>
                                <div class="col-lg-9 col-md-8 col-sm-8 col-8 fontsty">
                                    <h4>Cargadores</h4>
                                    <h2>{{ cargadores }}</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-6 col-12 mb-2 mt-4">
                        <div class="inforide">
                            <div class="row">
                                <div class="col-lg-3 col-md-4 col-sm-4 col-4 ridethree">
                                    <b-icon icon="arrow-down-up" style="width: 90%; height: 90%;"></b-icon>
                                </div>
                                <div class="col-lg-9 col-md-8 col-sm-8 col-8 fontsty">
                                    <h4>Turnos</h4>
                                    <h2>{{ turnos }}</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-6 col-12 mb-2 mt-4">
                        <div class="inforide">
                            <div class="row">
                                <div class="col-lg-3 col-md-4 col-sm-4 col-4 ridefour">
                                    <b-icon icon="people" style="width: 90%; height: 90%;"></b-icon>
                                </div>
                                <div class="col-lg-9 col-md-8 col-sm-8 col-8 fontsty">
                                    <h4>Sangrías</h4>
                                    <h2>{{ sangrias }}</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-6 col-12 mb-2 mt-4">
                        <div class="inforide">
                            <div class="row">
                                <div class="col-lg-3 col-md-4 col-sm-4 col-4 ridefive">
                                    <b-icon icon="piggy-bank" style="width: 90%; height: 90%;"></b-icon>
                                </div>
                                <div class="col-lg-9 col-md-8 col-sm-8 col-8 fontsty">
                                    <h4>Recaudación</h4>
                                    <h2>{{ recaudacion | moneyFormat }}</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <v-dialog v-model="showSessionExpiredDialog" max-width="400">
            <v-card>
                <v-card-title>¡Sesión expirada!</v-card-title>
                <v-card-text>
                    Tu sesión ha expirado. Por favor, inicia sesión nuevamente.
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="redirectToLogin">Iniciar sesión</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Header from '@/components/HeaderC.vue'
import { ref } from 'vue'
import axios from 'axios'

// eslint-disable-next-line no-unused-vars
const variants = ['elevated', 'flat', 'tonal', 'outlined']
// eslint-disable-next-line no-unused-vars
const color = ref('indigo')

export default {
    name: 'DashboardView',
    filters: {
        moneyFormat(value) {
            // Formatear el valor como "money" y separar por comas
            return "Q." + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },
    data: () => ({
        showSessionExpiredDialog: false,
        aspirantes: 0,
        cargadores: 0,
        turnos: 0,
        sangrias: 0,
        recaudacion: 0,
        loading: false,
        username: '',//this.$cookies.get('username'),//localStorage.username,
        name: ''//this.$cookies.get('name')//localStorage.name
    }),
    components: {
        Header
    },
    mounted: function () {
        this.initialize();
    },
    methods: {
        async initialize() {
            await this.validateToken();
            await this.obtieneRegistros();
        },
        redirectToLogin() {
            // Redirigir al usuario al componente de inicio de sesión
            this.$cookies.remove('token');
            this.$cookies.remove('user');
            this.$cookies.remove('username');
            this.$cookies.remove('name');

            //localStorage.removeItem('token');
            //localStorage.removeItem('user');
            //localStorage.removeItem('username');
            //localStorage.removeItem('name');
            this.$router.push('/');
        },
        validateToken() {

            //const token = localStorage.token
            const token = this.$cookies.get('token');
            // console.log('Token recuperado: ', token);
            this.loading = true;
            const apiUrl = `https://wm1xvvtarj.execute-api.us-east-1.amazonaws.com/dev/validateToken?token=${token}`;
            // Configuración para la solicitud GET con el token en el encabezado Authorization
            // console.log("apiurl", apiUrl)
            // Realizar solicitud GET a la API original con el token en el encabezado
            return axios.get(apiUrl)
                .then(response => {

                    const username = response.data.decoded.username;
                    const name = response.data.decoded.given_name + ' ' + response.data.decoded.family_name

                    this.$cookies.set('username', username, '1d', '', '', true, 'Strict');
                    this.$cookies.set('name', name, '1d', '', '', true, 'Strict');

                    //localStorage.username = response.data.decoded.username;
                    //localStorage.name = response.data.decoded.given_name + ' ' + response.data.decoded.family_name
                    this.username = username
                    this.name = name
                    this.validToken = true;
                    this.loading = false; // Desactivar animación de carga

                })
                .catch(() => {
                    this.validToken = false
                    this.showSessionExpiredDialog = true;
                    // console.error(error)
                    // console.error('Error al obtener datos de la API', error);
                    this.loading = false; // Desactivar animación de carga en caso de error
                    // console.log("sesion invalida")
                    //localStorage.clear
                    this.clearAllCookies;
                    // this.$router.push('/');

                });
        },
        clearAllCookies() {
            const allCookies = this.$cookies.keys();
            for (const cookie of allCookies) {
                this.$cookies.remove(cookie);
            }
            // console.log('All cookies cleared');
        },
        obtieneRegistros() {
            this.loading = true;
            const apiUrl = 'https://pc61cf7yyj.execute-api.us-east-1.amazonaws.com/dev/api/dashboard/1';
            const token = this.$cookies.get('token');
            // console.log(token)
            // Configuración para la solicitud GET con el token en el encabezado Authorization
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };



            return axios.get(apiUrl, config)
                .then(response => {

                    this.aspirantes = response.data.records[0].aspirantes;
                    this.cargadores = response.data.records[0].cargadores;
                    this.turnos = response.data.records[0].turnos;
                    this.sangrias = response.data.records[0].sangrias;
                    this.recaudacion = response.data.records[0].recaudacion;
                    // console.log(response.data.records)
                    // console.log(response.data.records[0].aspirantes)
                    this.loading = false; // Desactivar animación de carga
                    // console.log(this.ListaTurnos)
                })
                .catch(() => {
                    // console.error('Error al obtener datos de la API', error);
                    this.loading = false; // Desactivar animación de carga en caso de error
                });
        }

    }
}

</script>

<style scoped>
.inforide {
    box-shadow: 1px 2px 8px 0px #f1f1f1;
    background-color: white;
    border-radius: 8px;
    height: 125px;
}

.rideone b-icon-currency-dollar {
    width: 70%;
}

.ridetwo img {
    width: 60%;
}

.ridethree img {
    width: 50%;
}

.rideone {
    background-color: #6CC785;
    padding-top: 25px;
    border-radius: 8px 0px 0px 8px;
    text-align: center;
    height: 125px;
    margin-left: 15px;
}

.ridetwo {
    background-color: #9A75FE;
    padding-top: 30px;
    border-radius: 8px 0px 0px 8px;
    text-align: center;
    height: 125px;
    margin-left: 15px;
}

.ridethree {
    background-color: #4EBCE5;
    padding-top: 35px;
    border-radius: 8px 0px 0px 8px;
    text-align: center;
    height: 125px;
    margin-left: 15px;
}

.ridefour {
    background-color: #ec4300;
    padding-top: 35px;
    border-radius: 8px 0px 0px 8px;
    text-align: center;
    height: 125px;
    margin-left: 15px;
}

.ridefive {
    background-color: #d4e029;
    padding-top: 35px;
    border-radius: 8px 0px 0px 8px;
    text-align: center;
    height: 125px;
    margin-left: 15px;
}

.fontsty {
    margin-right: -15px;
}

.fontsty h2 {
    color: #6E6E6E;
    font-size: 35px;
    margin-top: 15px;
    text-align: right;
    margin-right: 30px;
}

.fontsty h4 {
    color: #6E6E6E;
    font-size: 25px;
    margin-top: 20px;
    text-align: right;
    margin-right: 30px;
}
</style>