<template>
    <div>
        <Header />
        <br />
        <div class="container">
            <v-overlay :value="loading">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-overlay>
            <Table :cargadores="Listacargadores" ref="tableComponent" />
        </div>
        <!-- <br>
        <button @click="Eliminar">Ejecutar Función</button> -->

    </div>
</template>

<script>
import Header from '@/components/HeaderC.vue'
import Table from '@/components/TableC.vue'
import axios from 'axios'

export default {
    name: 'InscripcionesView',
    data() {
        return {
            Listacargadores: [],
            loading: false,
            validToken: false
        }
    },
    components: {
        Header,
        Table
    },
    mounted: function () {

        this.obtieneRegistros();

    },
    methods: {
        validateToken() {

            // const token = localStorage.token
            const token = this.$cookies.get('token');
            // console.log('Ins Token recuperado: ', token);
            this.loading = true;
            // console.log('antes token')
            const apiUrl = `https://wm1xvvtarj.execute-api.us-east-1.amazonaws.com/dev/validateToken?token=${token}`;
            // console.log('despues token')
            // console.log('apiUrl', apiUrl)
            this.loading = true;
            // const apiUrl = `https://wm1xvvtarj.execute-api.us-east-1.amazonaws.com/dev/validateToken?token=${token}`;
            // Configuración para la solicitud GET con el token en el encabezado Authorization

            // console.log("apiurl", apiUrl)
            // Realizar solicitud GET a la API original con el token en el encabezado
            return axios.get(apiUrl)
                .then(response => {

                    const username = response.data.decoded.username;
                    this.$cookies.set('username', username, '1d', '', '', true, 'Strict');
                    // localStorage.username = response.data.decoded.username;
                    this.validToken = true;
                    this.loading = false; // Desactivar animación de carga

                })
                .catch(() => {
                    this.validToken = false
                    // console.error(error)
                    //  console.error('Error al obtener datos de la API', error);
                    this.loading = false; // Desactivar animación de carga en caso de error
                });
        },
        clearAllCookies() {
            const allCookies = this.$cookies.keys();
            for (const cookie of allCookies) {
                this.$cookies.remove(cookie);
            }
            // console.log('All cookies cleared');
        },
        async obtieneRegistros() {

            await this.validateToken();
            // console.log("antes de validar", this.validToken)
            if (!this.validToken) {
                // console.log("sesion invalida")
                // localStorage.clear
                this.clearAllCookies;
                // this.$router.push('/');
                return
            }
            // console.log("obtiene registros")
            const apiUrl = 'https://62jecp6v6f.execute-api.us-east-1.amazonaws.com/dev/api/cargadores';
            const token = this.$cookies.get('token');
            // Configuración para la solicitud GET con el token en el encabezado Authorization
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            this.loading = true; // Activar animación de carga
            // Realizar solicitud GET a la API original con el token en el encabezado
            axios.get(apiUrl, config)
                .then(response => {

                    this.Listacargadores = response.data.records[0]
                    // console.log(this.Listacargadores)
                    this.loading = false; // Desactivar animación de carga

                })
                .catch(() => {
                    // console.error('Error al obtener datos de la API', error);
                    this.loading = false; // Desactivar animación de carga en caso de error
                });
        }
    }
}
</script>

<style scoped></style>