<template>
  <div class="home">
    <div class="sidenav d-flex flex-column justify-content-center align-items-center">
      <img src="../assets/LogoHJDC.png" alt="Logo" class="logo-image">
      <div class="login-main-text">
        <h2>Hermandad<br> Jesús de la Caída</h2>
        <p>Inscripciones</p>
      </div>
    </div>
    <div class="main">
      <div class="col-md-6 col-sm-12">
        <div class="login-form">
          <form v-on:submit.prevent="login">
            <div class="form-group">
              <label>Usuario</label>
              <input type="text" class="form-control" placeholder="Usuario" v-model="usuario">
            </div>
            <div class="form-group">
              <label>Password</label>
              <input type="password" class="form-control" placeholder="Password" v-model="password">
            </div>
            <br />
            <button type="submit" class="btn btn-black" :disabled="loading">Iniciar Sesión</button>
            <br />
            <div class="alert alert-danger" role="alert" v-if="error">
              {{ error_msg }}
            </div>
          </form>
          <v-overlay :value="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-overlay>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';

export default {
  name: 'LoginView',
  components: {

  },
  data: function () {
    return {
      usuario: "",
      password: "",
      error: false,
      error_msg: "",
      loading: false
    }
  },
  methods: {
    login() {
      this.loading= true;
      // URL de la API de login
      const loginUrl = 'https://wm1xvvtarj.execute-api.us-east-1.amazonaws.com/dev/login';

      // Cuerpo de la solicitud para obtener el jwtToken
      const loginData = {
        username: this.usuario,
        password: this.password
      };

      // Realizar solicitud POST para obtener el jwtToken
      axios.post(loginUrl, loginData)
        .then(response => {
          if (response.data.status === 'Ok') {
            this.error = false;
            this.error_msg = ""
            this.loading = false; // Ocultar animación de carga
            const token = response.data.jwtToken;
            // console.log('login: ',token)
            this.$cookies.set('token', token, {
              expires: '1d',
              secure: true,
              httpOnly: true,
              sameSite: 'Strict'
            });
            // Verificar que se ha establecido correctamente
            // console.log('Token guardado sin secure: ', this.$cookies.get('token'));
            //localStorage.token = response.data.jwtToken;
            //localStorage.user = this.usuario;
            this.$cookies.set('user', this.usuario, '1d', '', '', true, 'Strict');
            this.$router.push('dashboard');

          } else {
            // console.error('Error en el inicio de sesión:', response.data);
            this.error = true;
            this.error_msg = response.data.error_msg
            this.loading=false;
          }
        })
        .catch(() => {
          // console.error('Error al iniciar sesión', error);
          this.loading=false;
        });

    }
  }
}
</script>


<style scoped>
.logo-image {
    max-width: 50%; /* La imagen ocupará el 50% del ancho del contenedor */
    height: auto; /* Esto mantiene la proporción de aspecto de la imagen */
  }

body {
  font-family: "Lato", sans-serif;
}



.main-head {
  height: 150px;
  background: #FFF;

}

.sidenav {
  height: 100%;
  background-color: #000;
  overflow-x: hidden;
  padding-top: 20px;
}


.main {
  padding: 0px 10px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
}

@media screen and (max-width: 450px) {
  .login-form {
    margin-top: 10%;
  }

  .register-form {
    margin-top: 10%;
  }
}

@media screen and (min-width: 768px) {
  .main {
    margin-left: 40%;
  }

  .sidenav {
    width: 40%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
  }

  .login-form {
    margin-top: 80%;
  }

  .register-form {
    margin-top: 20%;
  }
}


.login-main-text {
  margin-top: 0%;
  padding: 60px;
  color: #fff;
}

.login-main-text h2 {
  font-weight: 300;
}

.btn-black {
  background-color: #000 !important;
  color: #fff;
}
</style>